import { TLocales } from '../models/ILocales'

export const LOCALES: TLocales = {
    ru_RU: {
        USER_ENTER: 'Войти',
        SERVER_ERROR: 'Ошибка сервера',
        MAIN_NAME: 'Имя',
        LAST_NAME: 'Фамилия',
        PHONE: 'Телефон',
        SEX: 'Пол',
        SEX_MALE: 'Мужской',
        SEX_FEMALE: 'Женский',
        BIRTHDATE: 'Дата рождения',
        DATE_ERROR: 'Укажите дату верно',
        RECOMENDER_PHONE: 'Телефон рекомендателя',
        RECOMENDER_PHONE_ERROR: 'Укажите телефон рекомендателя верно',
        RECIVE_NEWSLETTER: 'Получать рассылку',
        AGREE_WITH: 'Согласен с',
        CONDITIONS: 'условиями',
        REGISTER: 'Зарегистрироваться',
        CHANGE_PHONE_NUMBER: 'Изменить номер телефона',
        SELECT_APP: 'Выберите приложение для добавления карты',
        SAVE_QR: 'Сохраните этот QR, если приложения не поддерживаются',
        SCANE_QR: 'Сканировать код для добавления карты',
        CREATE_NEW_REVIEW_PAGE: 'Создать страницу для получения отзывов',
        SHOW_ALL_REVIEW_PAGE: 'Посмотреть все страницы отзывов',
        STATISTIC: 'Статистика',
        PHONE_FORMAT: '+7 ### ### ## ##',
        TEXT_PUSH: 'Текстовый Push',
        GEO_PUSH: 'Geo-push',
        PUSH_SCHEDULER: 'Расписание уведомлений (редактирование)',
        SENDED_AND_RECEIVED: 'Отправлено / Получено',
        FEEDBACK_STATUS_A: 'Отлично',
        FEEDBACK_STATUS_B: 'Хорошо',
        FEEDBACK_STATUS_C: 'Удовлетворительно',
        FEEDBACK_STATUS_D: 'Плохо',
        FEEDBACK_STATUS_E: 'Ужасно',
        RAITINGS: 'Оценки',
        PERCENT: 'Процент',
        REVIEWS_AMOUNT: 'Кол-во ответов',
        ALL: 'Все',
        USER_NAME: 'Логин',
        PASSWORD: 'Пароль',
        LOGIN: 'Войти',
        RATING: 'Оценка',
        LEAVE_REVIEW: 'Оставить',
        SURVEY_HAS_ALREADY_PASSED: 'Вы уже проходили данный опрос.',
        THANKS_FOR_THE_FEEDBACK: 'Спасибо за отзыв!',
        ADD_THIS_CARD_TO_APPLEWALLET: 'Добавить карту в AppleWallet',
        ADD_THIS_CARD_TO_GOOGLEPAY: 'Добавить карту в GooglePay',
        GOOGLEPAY_CARD_DESIGN: 'Дизайн Google Pay карты',
        APPLEWALLET_CARD_DESIGN: 'Дизайн Apple Wallet карты',
        REGISTRATION_PAGE_DESIGN: 'Дизайн cтраницы регистрации карты',
        CARD_BACK_SIDE_DESIGN: 'Дизайн обратной стороны карты',
        GENDER_DEFAULT: 'Не указан',
        GENDER_MALE_SHORT: 'Муж.',
        GENDER_FEMALE_SHORT: 'Жен.',
        TOTAL_CLIENTS: 'Всего клиентов:',
        CLEAR_FILTERS: 'Очистить фильтры',
        BALANCE: 'Баланс',
        MAILING_LIST: 'Рассылка',
        DATE: 'Дата',
        MOBILE_PLATFORM: 'Платформа',
        POLL_REMOVED: 'Опрос удален.',
        DELETION_ERROR: 'Ошибка удаления',
        POLL_ID: 'Id опроса',
        POLL_NAME: 'Название',
        POLL_LINK: 'Ссылка',
        POLL_ACTIONS: 'Действия',
        REMOVE_LABEL: 'Удалить',
        FIELDS_ARE_NOT_FILLED: "Поля не заполнены",
        POLL_CREATED: "Опрос создан.",
        LINK_TO_POLL: "Ссылка на опрос",
        ENTER_NAME_OF_THE_POLL: "Введите название опроса",
        ENTER_TEXT_OF_THE_POLL: "Введите текст опроса",
        ADD_POLL: "Добавить опрос",
        DAY_NAME_SHORT_MON: "Пн",
        DAY_NAME_SHORT_TUES: "Вт",
        DAY_NAME_SHORT_WED: "Ср",
        DAY_NAME_SHORT_THURS: "Чт",
        DAY_NAME_SHORT_FRI: "Пт",
        DAY_NAME_SHORT_SAT: "Сб",
        DAY_NAME_SHORT_SUN: "Вс",
        ANDROID_PLATFORM_TEXT_PUSH_WARNING: "Пользователи Andoid получат рассылку по SMS, PUSH уведомления на платформе Andoid не доступно Проверьте доступность SMS пакета в http://iiko.biz",
        VALIDATION_ERROR_LAST_DATE_LESS_THAN_START_DATE: "Дата окончания не может быть меньше даты начала",
        VALIDATION_ERROR_DATES_INVALID: "Проверьте корректность заполнения дат",
        PUSH_CHANGE_SUCCESS: "Пуш изменен.",
        PUSH_CREATION_SUCCESS: "Пуш создан.",
        MESSAGE_HEADER: "Заголовок сообщения",
        HEADER: "Заголовок",
        SENDING_TIME_ACCORDING_TO_PLUS_THREE_TIME_BELT: "Время отправки по МСК",
        SENDING_TIME: "Время отправки",
        REMINDER_EXPIRATION_DATE: "Срок действия оповещения",
        MESSAGE_TEXT: "Tекст сообщения",
        MESSAGE_PUSH_TEXT: "Tекст push сообщения",
        MESSAGE_SMS_TEXT: "Tекст sms сообщения",
        MESSAGE_TG_TEXT: "Tекст tg сообщения",
        ENTER_PUSH_TEXT: "Введите текст пуша",
        ENTER_SMS_TEXT: "Введите текст смс",
        ENTER_TG_TEXT: "Введите текст для телеграмм",
        ADD_IMG_MSG_BTN_NAME: 'Изображение для TG сообщения ',
        HIDE_SPOILER_BTN_NAME: 'Скрыть изображение',
        SHOW_SPOILER_BTN_NAME: 'Показать изображение',
        TO_GUESTS_WITH_CATEGORIES: "Выберите категорию",
        TO_GUESTS_WITH_SOURCE: "Выберите источник трафика",
        TO_GUESTS_WITH_TAG: "Выберите тег",
        CATEGORIES:"Категории:",
        TRAFIC_SOURCES: "Источники трафика:",
        TAGS:"Теги:",
        SELECTED_ONLY: "Только выбранные",
        ALL_EXCEPT_SELECTED: "Все кроме выбранных",
        REPEAT_NEWSLETTER: "Повторять рассылку",
        REPETITION_TYPE: "тип повторений",
        DAILY_REPETITION_TYPE: "Ежедневно",
        WEEKLY_REPETITION_TYPE: "Еженедельно",
        MONTHLY_REPETITION_TYPE: "Ежемесячно",
        ANNUALLY_REPETITION_TYPE: "Ежегодно",
        WHAT_DAYS: "по каким дням",
        DAY_OF_MONTH: "день месяца",
        MONTH_LENGTH_WARNING: "если в месяце окажется меньшее число дней, то оповещение будет отправлено в последнее день месяца",
        MONTH: "месяц",
        SEND_TO_THE_SAME_GUESTS_NO_MORE_THAN: "отправлять одним и тем же гостям не чаще чем раз в",
        DAYS: "дней",
        GUESTS_WHO_VISITED_FOR_THE_LAST_TIME: "Гостям в последний раз посетившим заведение",
        UNTIL: "до",
        FROM: "от",
        DAYS_AGO: "дней назад",
        GUESTS_WHO_HAVE_BIRTHDAY: "Гостям у которых день рождения",
        IN_BIRTHDAY: "в день рождения",
        PER: "за",
        DAYS_BEFORE_BIRTHDAY: "дней до дня рождения",
        CHANGE_PUSH: "Изменить пуш",
        CREATE_PUSH: "Добавить пуш",
        ANDROID_PLATFORM_GEO_PUSH_WARNING: "Гео пуш недоступен для пользователей Android.",
        LATITUDE: "Широта",
        LONGITUDE: "Долгота",
        CHANGE_GEO_PUSH: "Изменить гео пуш",
        CREATE_GEO_PUSH: "Добавить гео пуш",
        PUSH_DELETING_SUCCESS: "Пуш удален.",
        TEXT_PUSHES: "Текстовые пуши",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ID: "Id",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_NAME: "Название",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Текст",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_PERIOD: "Период",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TIME: "Время",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_CATEGORIES: "Категории",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPEATABLE: "Повторяемый",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPETITION_TYPE: "Тип повторений",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Действия",
        TEXT_PUSHES_TABLE_YES: "да",
        TEXT_PUSHES_TABLE_NO: "нет",
        TEXT_PUSHES_TABLE_BIRTHDAY: "день рождения",
        TEXT_PUSHES_TABLE_PERIODIC: "периодический",
        ALT_TEXT_EDIT: "Редактировать",
        ALT_TEXT_DELETE: "Удалить",
        GEO_PUSHES: "Гео пуши",
        GEO_PUSHES_TABLE_COLUMN_TITLE_POLL_ID: "Id опроса",
        GEO_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Текст",
        GEO_PUSHES_TABLE_COLUMN_TITLE_THE_ADDRESS: "Адрес",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LATITUDE: "Широта",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LONGITUDE: "Долгота",
        GEO_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Действия",
        DESIGN_CREATION_SUCCESS: "Дизайн добавлен",
        DESIGN_UPDATING_SUCCESS: "Дизайн обновлен",
        BRIEF_CARD_DESCRIPTION: "Краткое описание карты",
        NOTIFICATION_TEXT: "Текст уведомления",
        BACKGROUND_COLOR_CODE: "Код цвета фона",
        ALT_TEXT_INFORMATION: "Информация",
        CARD_BACKGROUND_FORMAT_HINT_TOOLTIP: "Укажите фон карты в формате #f4f5b3 (можно брать с сайта csscolor.ru)",
        CARD_BACKGROUND_FORMAT_HINT: "Шестнадцатиричный код цвета фона (в формате #000000)",
        TEXT_COLOR_CODE: "Код цвета текста",
        CARD_FORMAT_HINT_TOOLTIP: "В формате #f4f5b3",
        CARD_FORMAT_HINT: "Шестнадцатиричный код цвета текста (в формате #000000)",
        HEADER_COLOR_CODE: "Код цвета заголовков",
        CARD_HEADER_HINT_TOOLTIP: "В формате #f4f5b3",
        CARD_HEADER_HINT: "Шестнадцатиричный код цвета заголовков (в формате #000000)",
        LOGO_TEXT: "Текст лого",
        TOP_FIELD_TITLE: "Заголовок верхнего поля",
        TOP_FIELD_TEXT: "Текст верхнего поля",
        MAIN_FIELD_TITLE: "Заголовок главного поля",
        MAIN_FIELD_TEXT: "Текст главного поля",
        SECONDARY_FIELD_TITLE: "Заголовок вторичного поля",
        SECONDARY_FIELD_TEXT: "Текст вторичного поля",
        ADDITIONAL_FIELD_TITLE: "Заголовок дополнительного поля",
        ADDITIONAL_FIELD_TEXT: "Текст дополнительного поля",
        ALTERNATIVE_TEXT: "Альтернативный текст",
        ICON: "Иконка",
        NOW: "Сейчас:",
        CENTRAL_IMAGE: "Центральное изображение",
        CENTRAL_IMAGE_HINT: "Приложите картинку изображения посередине карты",
        LOGOTYPE: "Логотип",
        LOGOTYPE_HINT: "Приложите картинку логотипа вашего заведения. На карте изображен вверху",
        CHANGE_SUBMIT_BUTTON: "Изменить",
        PREVIEW: "Предпросмотр",
        BACKGROUND: "Фон",
        BACKGROUND_MOBILE: "Мобильный фон",
        FORM_HEADER: "Заголовок формы",
        TEXT_COLOR: "Цвет текста",
        DISPLAY_ORGANIZATION_NAME: "Отображать название организации",
        POINTS_SHORT: "Б.",
        CATEGORY: "Категория",
        SILVER: "Серебряный",
        SHOW_BALANCE: "Отображать баланс",
        SHOW_CATEGORIES: "Отображать категории",
        IMAGE_IN_INFORMATION: "Изображение в сведениях",
        MAIN_IMAGE: "Главное изображение",
        TEXT_MODULE_HEADER: "Заголовок текстового модуля",
        LOGOTYPE_SHORT: "Лого",
        ALT_TEXT_CLOSE_MENU: "Закрыть меню",
        MISTERIOS_COMBOBOX_DEFAULT_VALUE: "Организация Get IT",
        ALT_TEXT_MENU: "Меню",
        ALT_TEXT_EXIT: "Выйти",
        ALT_TEXT_FILTER: "Фильтр",
        ALT_TEXT_SORT: "Сортировка",
        CODE_ALREADY_SENT: "Код уже отправлен",
        SERVICE_ERROR: "Ошибка сервиса, попробуйте позже",
        FAILED_TO_SEND_CODE: "Ошибка отправки кода, попробуйте позже",
        WRONG_CODE: "Код неверный.",
        CODE_SENT_TO_NUMBER: "Код отправлен на номер",
        ENTER_VALIDATION_DIGITS: "Введите последние 4 цифры",
        CODE_SUCCESSFULLY_SENT_TO_NUMBER: "Код успешно отправлен на номер",
        SEND_CODE_AGAIN: "Отправить код повторно",
        YOU_CAN_RESEND_THE_CODE_VIA: "Отправить код повторно можно через",
        MENU_CONST_HOME: "Главная",
        MENU_CONST_DESIGN: "Дизайн",
        MENU_CONST_PUSH_MESSAGES: "Push-сообщения",
        MENU_CONST_REVIEWS: "Отзывы",
        MENU_CONST_CLIENTS: "Клиенты",
        FILL_OUT_THE_REQUIRED_FIELD: "Заполните обязательное поле",
        Display_in_telegram_bot: "Отображать в телеграмм боте",
        Put_custom_text_on_testimonials_page: "Поставить индивидуальный текст на странице с отзывами",
        Provide_a_link_to_redirect_positive_reviews: "Укажите ссылку для переадресации положительных отзывов",
        Tell_me_what_you_did_not_like: "Расскажите что вам не понравилось",
        Thanks_for_the_feedback: "Спасибо за отзыв!",
        Thank_you: "Спасибо! Оставьте о нас отзыв",
        Link_to_external_resource_for_feedback: "Ссылка на внешний ресурс для отзыва",
        Please_enter_valid_phone_number: "Введите корректный номер телефона",
        Submit_an_estimate: "Отправить оценку",
        Go: "Перейти",
        Numbers_and_symbols_are_not_allowed: "Цифры и символы не допустимы",
        LOYAITY_PROGRAM: "Участвовать в программе лояльности",
        FEEDBACK_STATISTICS: "Статистика по отзывам",
        CARD_STATISTICS: "Статистика по гостям",
        SELECT_PERIOD: "Выберите период:",
        TODAY: "Сегодня",
        MONTH_: 'Месяц',
        QUARTER: 'Квартал',
        YEAR: 'Год',
        REFRESH: 'Обновить',
        GUESTS: 'Гости',
        WORKING_WITH_GUESTS: 'Работа с гостями',
        COMMUNICATION_TYPE: 'Тип коммуникации',
        TOTAL_GUESTS: 'Всего гостей',
        TOTAL_REVIEWS: 'Всего отзывов',
        USE_TG_BOT: 'Используют TG бот',
        FOR_SELECTED_PERIOD: 'За выбранный период',
        REFERRAL_LINK: 'Зарегистрировались по реф.ссылке',
        USE_APPLE:'Используют Apple Wallet',
        PREV_PERIOD: 'В предыдущем периоде',
        MESSAGES_DELIVERED: 'Доставлено сообщений',
        USE_GOOGLE:'Используют Google Pay',
        MENU_CONST_UTM: 'UTM',
        CREATE_UTM: 'Создать UTM',
        TOTAL_UTM: 'Всего :',
        UTM_NAME: 'Имя метки',
        UTM_SOURCE: 'Источник трафика',
        UTM_TAG: 'Тег',
        UTM_CREATED: 'Дата создания',
        LABEL_NAME: 'имя метки:',
        SAVE: 'Сохранить',
        CLOSE: 'Закрыть',
        CREATE_SOURCE: 'Создать источник трафика',
        CREATE_TAG: 'Создать тег',
        USERS: 'Пользователи',
        LINK: 'Ссылка',
        ENTER_CORRECT_VALUES: 'Введите правильные значения',
        ENTER_SOURCE_VALUE:' введите значение источника:',
        SOURCE_VALUE: 'Значение источника',
        ENTER_TAG_VALUE: 'введите значение тега:',
        TAG_VALUE:'Значение тега',
        SELECT_SOURCE: 'выберите источник трафика',
        SELECT_TAG:'выберите тег',
        LINK_COPIED: 'Ссылка скопирована',
        TEXT_FIELD: 'Заполните хотя бы одно текстовое поле',
        Choose_all:'выбрать все',
        Select_at_least_one_day:'Выберите хотя бы один день',
        use_Apple_products: 'Пользуетесь продукцией Apple? Тогда нажмите сюда',
        Open_in_Safari: 'Открыть в Safari'
    },
    ua_UA: {
        USER_ENTER: 'Увійти',
        SERVER_ERROR: "Помилка серверу",
        MAIN_NAME: "Ім'я",
        LAST_NAME: 'Прізвище',
        PHONE: 'Телефон',
        SEX: 'Стать',
        SEX_MALE: 'Чоловіча',
        SEX_FEMALE: 'Жіноча',
        BIRTHDATE: 'Дата народження',
        DATE_ERROR: 'Вкажіть дату правильно',
        RECOMENDER_PHONE: "Телефон рекомендатора",
        RECOMENDER_PHONE_ERROR: 'Вкажіть телефон рекомендатора правильно',
        RECIVE_NEWSLETTER: 'Отримувати розсилку',
        AGREE_WITH: 'Згідний з',
        CONDITIONS: 'Умовами',
        REGISTER: 'Зареєструватися',
        CHANGE_PHONE_NUMBER: 'Змінити номер телефону',
        SELECT_APP: 'Оберіть додаток для додавання картки',
        SAVE_QR: 'Збережіть цей QR, якщо додатки не підтримуються',
        SCANE_QR: 'Сканировать код для добавления карты',
        CREATE_NEW_REVIEW_PAGE: "Створити сторінку для отримання відгуків",
        SHOW_ALL_REVIEW_PAGE: "Переглянути всі сторінки відгуків",
        STATISTIC: "Статистика",
        PHONE_FORMAT: "+380 ## ### ## ##",
        TEXT_PUSH: "Текстовий Push",
        GEO_PUSH: "Geo-push",
        PUSH_SCHEDULER: "Розклад повідомлень (редагування)",
        SENDED_AND_RECEIVED: "Відправлено / Отримано",
        FEEDBACK_STATUS_A: "Відмінно",
        FEEDBACK_STATUS_B: "Добре",
        FEEDBACK_STATUS_C: "Задовільно",
        FEEDBACK_STATUS_D: "Погано",
        FEEDBACK_STATUS_E: "Жахливо",
        RAITINGS: "Оцінки",
        PERCENT: "Відсоток",
        REVIEWS_AMOUNT: "Кількість відповідей",
        ALL: "Всі",
        USER_NAME: "Логін",
        PASSWORD: "Пароль",
        LOGIN: "Увійти",
        RATING: "Оцінка",
        LEAVE_REVIEW: "Залiшiтi",
        SURVEY_HAS_ALREADY_PASSED: "Ви вже проходили це опитування.",
        THANKS_FOR_THE_FEEDBACK: "Дякую за відгук!",
        ADD_THIS_CARD_TO_APPLEWALLET: "Додати карту до Apple Wallet",
        ADD_THIS_CARD_TO_GOOGLEPAY: "Додати карту до GooglePay",
        GOOGLEPAY_CARD_DESIGN: "Дизайн Google Pay картки",
        APPLEWALLET_CARD_DESIGN: "Дизайн Apple Wallet картки",
        REGISTRATION_PAGE_DESIGN: "Дизайн сторінки реєстрації картки",
        CARD_BACK_SIDE_DESIGN: "Дизайн зворотного боку картки",
        GENDER_DEFAULT: "Не вказано",
        GENDER_MALE_SHORT: "Чол.",
        GENDER_FEMALE_SHORT: "Жін.",
        TOTAL_CLIENTS: "Усього клієнтів:",
        CLEAR_FILTERS: "Очистити фільтри",
        BALANCE: "Баланс",
        MAILING_LIST: "Розсилка",
        DATE: "Дата",
        MOBILE_PLATFORM: "Платформа",
        POLL_REMOVED: "Опитування видалено.",
        DELETION_ERROR: "Помилка видалення",
        POLL_ID: "Id опитування",
        POLL_NAME: "Назва",
        POLL_LINK: "Посилання",
        POLL_ACTIONS: "Дії",
        REMOVE_LABEL: "видалити",
        FIELDS_ARE_NOT_FILLED: "Поля не заповнені",
        POLL_CREATED: "Опитування створено.",
        LINK_TO_POLL: "Посилання на опитування",
        ENTER_NAME_OF_THE_POLL: "Введіть назву опитування",
        ENTER_TEXT_OF_THE_POLL: "Введіть текст опитування",
        ADD_POLL: "Додати опитування",
        DAY_NAME_SHORT_MON: "Пн",
        DAY_NAME_SHORT_TUES: "Вт",
        DAY_NAME_SHORT_WED: "Ср",
        DAY_NAME_SHORT_THURS: "Чт",
        DAY_NAME_SHORT_FRI: "Пт",
        DAY_NAME_SHORT_SAT: "Сб",
        DAY_NAME_SHORT_SUN: "Нд",
        ANDROID_PLATFORM_TEXT_PUSH_WARNING: "Користувачі Andoid отримають розсилку SMS, PUSH повідомлення на платформі Andoid не доступно. Перевірте доступність SMS, через тех.підтримку.",
        VALIDATION_ERROR_LAST_DATE_LESS_THAN_START_DATE: "Дата закінчення не може бути менше дати початку",
        VALIDATION_ERROR_DATES_INVALID: "Перевірте правильність заповнення дат",
        PUSH_CHANGE_SUCCESS: "Пуш змінено.",
        PUSH_CREATION_SUCCESS: "Пуш створено.",
        MESSAGE_HEADER: "Заголовок повідомлення",
        HEADER: "Заголовок",
        SENDING_TIME_ACCORDING_TO_PLUS_THREE_TIME_BELT: "Час відправки",
        SENDING_TIME: "Час відправки",
        REMINDER_EXPIRATION_DATE: "Строк дії оповіщення",
        MESSAGE_TEXT: "Текст повідомлення",
        MESSAGE_PUSH_TEXT: "Текст push повідомлення",
        MESSAGE_SMS_TEXT: "Текст SMS повідомлення",
        MESSAGE_TG_TEXT: "Текст tg повідомлення",
        ENTER_PUSH_TEXT: "Введіть текст пуша",
        ENTER_SMS_TEXT: "Введіть текст смс", 
        ENTER_TG_TEXT: "Введіть текст для телеграм",
        ADD_IMG_MSG_BTN_NAME: 'Зображення для TG повідомлення', 
        HIDE_SPOILER_BTN_NAME: 'Приховати зображення', 
        SHOW_SPOILER_BTN_NAME: 'Показати зображення', 
        TO_GUESTS_WITH_CATEGORIES: "Оберіть категорію",
        TO_GUESTS_WITH_SOURCE: "Виберіть джерело трафіку",
        TO_GUESTS_WITH_TAG: "Виберіть тег",
        CATEGORIES:"Категорії:",
        TRAFIC_SOURCES: "Джерела трафіку:",
        TAGS:"Теги:",
        SELECTED_ONLY: "Тільки вибрані",
        ALL_EXCEPT_SELECTED: "Усі крім обраних",
        REPEAT_NEWSLETTER: "Повторювати розсилку",
        REPETITION_TYPE: "тип повторень",
        DAILY_REPETITION_TYPE: "Щодня",
        WEEKLY_REPETITION_TYPE: "Щотижня",
        MONTHLY_REPETITION_TYPE: "Щомісяця",
        ANNUALLY_REPETITION_TYPE: "Щорічно",
        WHAT_DAYS: "по яких днях",
        DAY_OF_MONTH: "день місяця",
        MONTH_LENGTH_WARNING: "якщо в місяці виявиться менше днів, то сповіщення буде відправлено в останній день місяця",
        MONTH: "місяць",
        SEND_TO_THE_SAME_GUESTS_NO_MORE_THAN: "відправляти одним і тим же гостям не частіше ніж раз у",
        DAYS: "днів",
        GUESTS_WHO_VISITED_FOR_THE_LAST_TIME: "Гостям, які востаннє відвідали заклад",
        UNTIL: "до",
        FROM: "від",
        DAYS_AGO: "днів тому",
        GUESTS_WHO_HAVE_BIRTHDAY: "Гостям у яких день народження",
        IN_BIRTHDAY: "в день народження",
        PER: "за",
        DAYS_BEFORE_BIRTHDAY: "днів до дня народження",
        CHANGE_PUSH: "Змінити пуш",
        CREATE_PUSH: "Додати пуш",
        ANDROID_PLATFORM_GEO_PUSH_WARNING: "Гео пуш недоступний для користувачів Android.",
        LATITUDE: "Широта",
        LONGITUDE: "Довгота",
        CHANGE_GEO_PUSH: "Змінити гео пуш",
        CREATE_GEO_PUSH: "Додати гео пуш",
        PUSH_DELETING_SUCCESS: "Пуш видалено.",
        TEXT_PUSHES: "Текстові гармати",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ID: "Id",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_NAME: "Назва",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Текст",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_PERIOD: "Період",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TIME: "Час",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_CATEGORIES: "Категорії",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPEATABLE: "Повторюваний",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPETITION_TYPE: "Тип повторень",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Дії",
        TEXT_PUSHES_TABLE_YES: "так",
        TEXT_PUSHES_TABLE_NO: "ні",
        TEXT_PUSHES_TABLE_BIRTHDAY: "день народження",
        TEXT_PUSHES_TABLE_PERIODIC: "періодичний",
        ALT_TEXT_EDIT: "Редагувати",
        ALT_TEXT_DELETE: "Видалити",
        GEO_PUSHES: "Гео пуші",
        GEO_PUSHES_TABLE_COLUMN_TITLE_POLL_ID: "Id опитування",
        GEO_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Текст",
        GEO_PUSHES_TABLE_COLUMN_TITLE_THE_ADDRESS: "Адреса",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LATITUDE: "Широта",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LONGITUDE: "Довгота",
        GEO_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Дії",
        DESIGN_CREATION_SUCCESS: "Дизайн доданий",
        DESIGN_UPDATING_SUCCESS: "Дизайн оновлено",
        BRIEF_CARD_DESCRIPTION: "Короткий опис карти",
        NOTIFICATION_TEXT: "Текст повідомлення",
        BACKGROUND_COLOR_CODE: "Код кольору фону",
        ALT_TEXT_INFORMATION: "Інформація",
        CARD_BACKGROUND_FORMAT_HINT_TOOLTIP: "Вкажіть фон карти у форматі #f4f5b3 (можна брати із сайту https://html-color-codes.info/Cvetovye-kody-HTML/)",
        CARD_BACKGROUND_FORMAT_HINT: "Шістнадцятковий код кольору фону (у форматі #000000)",
        TEXT_COLOR_CODE: "Код кольору тексту",
        CARD_FORMAT_HINT_TOOLTIP: "У форматі #f4f5b3",
        CARD_FORMAT_HINT: "Шістнадцятковий код кольору тексту (у форматі #000000)",
        HEADER_COLOR_CODE: "Код кольору заголовків",
        CARD_HEADER_HINT_TOOLTIP: "У форматі #f4f5b3",
        CARD_HEADER_HINT: "Шістнадцятковий код кольору заголовків (у форматі #000000)",
        LOGO_TEXT: "Текст лого",
        TOP_FIELD_TITLE: "Заголовок верхнього поля",
        TOP_FIELD_TEXT: "Текст верхнього поля",
        MAIN_FIELD_TITLE: "Заголовок головного поля",
        MAIN_FIELD_TEXT: "Текст головного поля",
        SECONDARY_FIELD_TITLE: "Заголовок вторинного поля",
        SECONDARY_FIELD_TEXT: "Текст вторинного поля",
        ADDITIONAL_FIELD_TITLE: "Заголовок додаткового поля",
        ADDITIONAL_FIELD_TEXT: "Текст додаткового поля",
        ALTERNATIVE_TEXT: "Альтернативний текст",
        ICON: "Значок",
        NOW: "Зараз:",
        CENTRAL_IMAGE: "Центральне зображення",
        CENTRAL_IMAGE_HINT: "Прикладіть зображення посередині картки",
        LOGOTYPE: "Логотип",
        LOGOTYPE_HINT: "Додайте картинку логотипу вашого закладу. На карті зображено зверху",
        CHANGE_SUBMIT_BUTTON: "Змінити",
        PREVIEW: "Передпрогляд",
        BACKGROUND: "Фон",
        BACKGROUND_MOBILE: "Мобільний фон",
        FORM_HEADER: "Заголовок форми",
        TEXT_COLOR: "Колір тексту",
        DISPLAY_ORGANIZATION_NAME: "Відображати назву організації",
        POINTS_SHORT: "Б.",
        CATEGORY: "Категорія",
        SILVER: "Срібний",
        SHOW_BALANCE: "Відображати баланс",
        SHOW_CATEGORIES: "Відображати категорії",
        IMAGE_IN_INFORMATION: "Зображення у відомостях",
        MAIN_IMAGE: "Головне зображення",
        TEXT_MODULE_HEADER: "Заголовок текстового модуля",
        LOGOTYPE_SHORT: "Лого",
        ALT_TEXT_CLOSE_MENU: "Закрити меню",
        MISTERIOS_COMBOBOX_DEFAULT_VALUE: "Организация SmartCafe",
        ALT_TEXT_MENU: "Меню",
        ALT_TEXT_EXIT: "Вийти",
        ALT_TEXT_FILTER: "Фільтр",
        ALT_TEXT_SORT: "Сортування",
        CODE_ALREADY_SENT: "Код вже надіслано",
        SERVICE_ERROR: "Помилка сервісу, спробуйте пізніше",
        FAILED_TO_SEND_CODE: "Помилка надсилання коду, спробуйте пізніше",
        WRONG_CODE: "Код неправильний.",
        CODE_SENT_TO_NUMBER: "Код надіслано на номер",
        ENTER_VALIDATION_DIGITS: "Введіть останні 4 цифри",
        CODE_SUCCESSFULLY_SENT_TO_NUMBER: "Код успішно надіслано на номер",
        SEND_CODE_AGAIN: "Надіслати код повторно",
        YOU_CAN_RESEND_THE_CODE_VIA: "Надіслати код повторно можна через",
        MENU_CONST_HOME: "Головна",
        MENU_CONST_DESIGN: "Дизайн",
        MENU_CONST_PUSH_MESSAGES: "Push-повідомлення",
        MENU_CONST_REVIEWS: "Відгуки",
        MENU_CONST_CLIENTS: "Клієнти",
        FILL_OUT_THE_REQUIRED_FIELD: "Заповніть обов'язкове поле",
        Display_in_telegram_bot: "Відображати в телеграм боті",
        Put_custom_text_on_testimonials_page: "Поставити індивідуальний текст на сторінці з відгуками",
        Provide_a_link_to_redirect_positive_reviews: "Вкажіть посилання для переадресації позитивних відгуків",
        Tell_me_what_you_did_not_like: "Розкажіть, що вам не сподобалося",
        Thanks_for_the_feedback: "Дякую за відгук!",
        Thank_you: "Дякую! Залишіть про нас відгук",
        Link_to_external_resource_for_feedback: "Посилання на зовнішній ресурс для відкликання",
        Please_enter_valid_phone_number: "Введіть номер телефону.",
        Submit_an_estimate: "Надіслати оцінку",
        Go: "Перейти",
        Numbers_and_symbols_are_not_allowed: "Цифри та символи не допустимі",
        LOYAITY_PROGRAM: "Брати участь у програмі лояльності",
        FEEDBACK_STATISTICS: "Статистика відгуків",
        CARD_STATISTICS: "Статистика по гостям",
        SELECT_PERIOD: "Виберіть період",
        TODAY: "Сьогодні",
        MONTH_: 'Місяць',
        QUARTER: 'Квартал',
        YEAR: 'Рік',
        REFRESH: 'Оновити',
        GUESTS: 'Гості',
        WORKING_WITH_GUESTS: 'Робота з гостями',
        COMMUNICATION_TYPE: 'Тип Комунікації',
        TOTAL_GUESTS: 'Усього гостей',
        TOTAL_REVIEWS: 'Усього відгуків',
        USE_TG_BOT: 'Використовують TG бот',
        FOR_SELECTED_PERIOD: 'За вибраний період',
        REFERRAL_LINK: 'Перейшли за реф. посиланням',
        USE_APPLE:'Використовують Apple Wallet',
        PREV_PERIOD: 'У попередньому періоді',
        MESSAGES_DELIVERED: 'Надіслано повідомлень',
        USE_GOOGLE:'Використовують Google Pay',
        MENU_CONST_UTM: 'UTM',
        TOTAL_UTM: 'Усього :',
        CREATE_UTM: "Створити UTM",
        UTM_NAME: "Ім'я мітки",
        UTM_SOURCE: 'Джерело трафіку',
        UTM_TAG: 'Тег',
        UTM_CREATED: 'Дата створення',
        LABEL_NAME: "iм'я UTM:",
        SAVE: 'Зберегти',
        CLOSE: 'Закрити',
        CREATE_SOURCE: 'Створити джерело трафіку',
        CREATE_TAG: 'Створити тег',
        USERS: 'Користувачі',
        LINK: 'Посилання',
        ENTER_CORRECT_VALUES: 'Введіть правильні значення',
        ENTER_SOURCE_VALUE:' введіть значення джерела:',
        SOURCE_VALUE: 'Значення джерела',
        ENTER_TAG_VALUE: 'введіть значення тега:',
        TAG_VALUE:'Значення тега',
        SELECT_SOURCE: 'виберіть джерело трафіку',
        SELECT_TAG:'виберіть тег',
        LINK_COPIED: 'Посилання скопійоване',
        TEXT_FIELD: 'Заповніть хоча б одне текстове поле',
        Choose_all:'обрати все',
        Select_at_least_one_day:'Виберіть хоча б один день',
        use_Apple_products:'Ви користуєтесь продукцією Apple? Тоді притисни сюди',
        Open_in_Safari: 'Відкрити в Safari'
    },
  en_EN: {
      USER_ENTER: 'Sign in',
        SERVER_ERROR: 'Server error',
        MAIN_NAME: 'Name',
        LAST_NAME: 'Surname',
        PHONE: 'Phone',
        SEX: 'Gender',
        SEX_MALE: 'Male',
        SEX_FEMALE: 'Female',
        BIRTHDATE: 'Date of Birth',
        DATE_ERROR: 'Please enter the correct date',
        RECOMENDER_PHONE: 'Recommender phone number',
        RECOMENDER_PHONE_ERROR: 'Enter the recommender phone number correctly',
        RECIVE_NEWSLETTER: 'Receive newsletter',
        AGREE_WITH: 'Agree with',
        CONDITIONS: 'conditions',
        REGISTER: 'Register',
        CHANGE_PHONE_NUMBER: 'Change phone number',
        SELECT_APP: 'Choose an application to add a card',
        SAVE_QR: 'Save this QR if apps are not supported',
        SCANE_QR: 'Scan code to add a card',
        CREATE_NEW_REVIEW_PAGE: 'Create a Feedback Page',
        SHOW_ALL_REVIEW_PAGE: 'View all testimonial pages',
        STATISTIC: 'Statistics',
        PHONE_FORMAT: '+1 ### ###-####',
        TEXT_PUSH: 'Text Push',
        GEO_PUSH: 'Geo-push',
        PUSH_SCHEDULER: 'Notification schedule (editing)',
        SENDED_AND_RECEIVED: 'Sent / Received',
        FEEDBACK_STATUS_A: 'Excellent!',
        FEEDBACK_STATUS_B: 'Good!',
        FEEDBACK_STATUS_C: 'Satisfactorily',
        FEEDBACK_STATUS_D: 'Badly',
        FEEDBACK_STATUS_E: 'Terrible',
        RAITINGS: 'Ratings',
        PERCENT: 'Percent',
        REVIEWS_AMOUNT: 'Number of answers',
        ALL: 'All',
        USER_NAME: 'Login',
        PASSWORD: 'Password',
        LOGIN: 'Sign in',
        RATING: 'Grade',
        LEAVE_REVIEW: 'Post a review',
        SURVEY_HAS_ALREADY_PASSED: 'You have already completed this survey.',
        THANKS_FOR_THE_FEEDBACK: 'Thanks for the feedback!',
        ADD_THIS_CARD_TO_APPLEWALLET: 'Add a card to Apple Wallet',
        ADD_THIS_CARD_TO_GOOGLEPAY: 'Add a card to Google Pay',
        GOOGLEPAY_CARD_DESIGN: 'Google Pay card design',
        APPLEWALLET_CARD_DESIGN: 'Apple Wallet card design',
        REGISTRATION_PAGE_DESIGN: 'Card registration page design',
        CARD_BACK_SIDE_DESIGN: 'Card back design',
        GENDER_DEFAULT: 'Not specified',
        GENDER_MALE_SHORT: 'Male',
        GENDER_FEMALE_SHORT: 'Female',
        TOTAL_CLIENTS: 'Total clients:',
        CLEAR_FILTERS: 'Clear filters',
        BALANCE: 'Balance',
        MAILING_LIST: 'Newsletter',
        DATE: 'Date',
        MOBILE_PLATFORM: 'Platform',
        POLL_REMOVED: 'The poll has been deleted.',
        DELETION_ERROR: 'Deletion error',
        POLL_ID: 'Poll ID',
        POLL_NAME: 'Name',
        POLL_LINK: 'Link',
        POLL_ACTIONS: 'Actions',
        REMOVE_LABEL: 'Delete',
        FIELDS_ARE_NOT_FILLED: "Fields are not filled",
        POLL_CREATED: "Poll created.",
        LINK_TO_POLL: "Link to survey",
        ENTER_NAME_OF_THE_POLL: "Enter the name of the survey",
        ENTER_TEXT_OF_THE_POLL: "Enter survey text",
        ADD_POLL: "Add Poll",
        DAY_NAME_SHORT_MON: "Mon",
        DAY_NAME_SHORT_TUES: "Tue",
        DAY_NAME_SHORT_WED: "Wed",
        DAY_NAME_SHORT_THURS: "Thu",
        DAY_NAME_SHORT_FRI: "Fri",
        DAY_NAME_SHORT_SAT: "Sat",
        DAY_NAME_SHORT_SUN: "Sun",
        ANDROID_PLATFORM_TEXT_PUSH_WARNING: "Andoid users will receive SMS notifications, PUSH notifications are not available on the Andoid platform Check the availability of the SMS package at http://iiko.biz",
        VALIDATION_ERROR_LAST_DATE_LESS_THAN_START_DATE: "The end date cannot be less than the start date",
        VALIDATION_ERROR_DATES_INVALID: "Check if the dates are filled in correctly",
        PUSH_CHANGE_SUCCESS: "Push changed.",
        PUSH_CREATION_SUCCESS: "Push created.",
        MESSAGE_HEADER: "The headline of the message",
        HEADER: "Header",
        SENDING_TIME_ACCORDING_TO_PLUS_THREE_TIME_BELT: "Sending time according to Moscow time",
        SENDING_TIME: "Dispatch time",
        REMINDER_EXPIRATION_DATE: "Alert expiration date",
        MESSAGE_TEXT: "Message text",
        MESSAGE_PUSH_TEXT: "Text push message",
        MESSAGE_SMS_TEXT: "Text sms message",
        MESSAGE_TG_TEXT: "Text tg message",
        ENTER_PUSH_TEXT: "Enter push text",
        ENTER_SMS_TEXT: "Enter sms text",
        ENTER_TG_TEXT: "Enter tg text",
        ADD_IMG_MSG_BTN_NAME: 'Post image for TG message',
        HIDE_SPOILER_BTN_NAME: 'Hide image',
        SHOW_SPOILER_BTN_NAME: 'Show picture',
        TO_GUESTS_WITH_CATEGORIES: "Select a category",
        TO_GUESTS_WITH_SOURCE: "Select a traffic source",
        TO_GUESTS_WITH_TAG: "Select a tag",
        CATEGORIES:"Categories:",
        TRAFIC_SOURCES: "Traffic sources:",
        TAGS:"Tags:",
        SELECTED_ONLY: "Selected only",
        ALL_EXCEPT_SELECTED: "All except selected",
        REPEAT_NEWSLETTER: "Repeat newsletter",
        REPETITION_TYPE: "Repetition type",
        DAILY_REPETITION_TYPE: "Daily",
        WEEKLY_REPETITION_TYPE: "Weekly",
        MONTHLY_REPETITION_TYPE: "Monthly",
        ANNUALLY_REPETITION_TYPE: "Annually",
        WHAT_DAYS: "What days",
        DAY_OF_MONTH: "Day of the month",
        MONTH_LENGTH_WARNING: "If there are fewer days in a month, the notification will be sent on the last day of the month",
        MONTH: "month",
        SEND_TO_THE_SAME_GUESTS_NO_MORE_THAN: "send to the same guests no more than once a",
        DAYS: "days",
        GUESTS_WHO_VISITED_FOR_THE_LAST_TIME: "Guests who visited the establishment for the last time",
        UNTIL: "before",
        FROM: "from",
        DAYS_AGO: "days ago",
        GUESTS_WHO_HAVE_BIRTHDAY: "Guests who have a birthday",
        IN_BIRTHDAY: "birthday",
        PER: "behind",
        DAYS_BEFORE_BIRTHDAY: "days before birthday",
        CHANGE_PUSH: "Change Push",
        CREATE_PUSH: "Add Push",
        ANDROID_PLATFORM_GEO_PUSH_WARNING: "Geo push is not available for Android users.",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        CHANGE_GEO_PUSH: "Change geo push",
        CREATE_GEO_PUSH: "Add geo push",
        PUSH_DELETING_SUCCESS: "Push removed.",
        TEXT_PUSHES: "Text pushes",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ID: "Id",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_NAME: "Name",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Text",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_PERIOD: "Period",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_TIME: "Time",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_CATEGORIES: "Categories",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPEATABLE: "Repeatable",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_REPETITION_TYPE: "Repetition type",
        TEXT_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Actions",
        TEXT_PUSHES_TABLE_YES: "Yes",
        TEXT_PUSHES_TABLE_NO: "No",
        TEXT_PUSHES_TABLE_BIRTHDAY: "birthday",
        TEXT_PUSHES_TABLE_PERIODIC: "periodic",
        ALT_TEXT_EDIT: "Edit",
        ALT_TEXT_DELETE: "Delete",
        GEO_PUSHES: "Geo pushes",
        GEO_PUSHES_TABLE_COLUMN_TITLE_POLL_ID: "Poll ID",
        GEO_PUSHES_TABLE_COLUMN_TITLE_TEXT: "Text",
        GEO_PUSHES_TABLE_COLUMN_TITLE_THE_ADDRESS: "Address",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LATITUDE: "Latitude",
        GEO_PUSHES_TABLE_COLUMN_TITLE_LONGITUDE: "Longitude",
        GEO_PUSHES_TABLE_COLUMN_TITLE_ACTIONS: "Actions",
        DESIGN_CREATION_SUCCESS: "Design added",
        DESIGN_UPDATING_SUCCESS: "Design updated",
        BRIEF_CARD_DESCRIPTION: "Brief description of the map",
        NOTIFICATION_TEXT: "Notification text",
        BACKGROUND_COLOR_CODE: "Background color code",
        ALT_TEXT_INFORMATION: "Information",
        CARD_BACKGROUND_FORMAT_HINT_TOOLTIP: "Specify the background of the map in the format #f4f5b3 (you can take it from csscolor.ru)",
        CARD_BACKGROUND_FORMAT_HINT: "Background color hexadecimal code (in the format #000000)",
        TEXT_COLOR_CODE: "Text color code",
        CARD_FORMAT_HINT_TOOLTIP: "In the format #f4f5b3",
        CARD_FORMAT_HINT: "Text color hexadecimal code (in the format #000000)",
        HEADER_COLOR_CODE: "Header color code",
        CARD_HEADER_HINT_TOOLTIP: "In the format #f4f5b3",
        CARD_HEADER_HINT: "Heading color hexadecimal code (in the format #000000)",
        LOGO_TEXT: "Logo text",
        TOP_FIELD_TITLE: "Top field title",
        TOP_FIELD_TEXT: "Top margin text",
        MAIN_FIELD_TITLE: "Heading of the main field",
        MAIN_FIELD_TEXT: "Main field text",
        SECONDARY_FIELD_TITLE: "Secondary field title",
        SECONDARY_FIELD_TEXT: "Secondary field text",
        ADDITIONAL_FIELD_TITLE: "Additional field header",
        ADDITIONAL_FIELD_TEXT: "Additional field text",
        ALTERNATIVE_TEXT: "Alternative text",
        ICON: "Icon",
        NOW: "Now:",
        CENTRAL_IMAGE: "Central image",
        CENTRAL_IMAGE_HINT: "Attach a picture of the image in the middle of the card",
        LOGOTYPE: "Logo",
        LOGOTYPE_HINT: "Attach a picture of your institution's logo. Shown on the map above",
        CHANGE_SUBMIT_BUTTON: "Change",
        PREVIEW: "Preview",
        BACKGROUND: "Background",
        BACKGROUND_MOBILE: "Mobile background",
        FORM_HEADER: "Form header",
        TEXT_COLOR: "Text color",
        DISPLAY_ORGANIZATION_NAME: "Display organization name",
        POINTS_SHORT: "b",
        CATEGORY: "Category",
        SILVER: "Silver",
        SHOW_BALANCE: "Show balance",
        SHOW_CATEGORIES: "Show categories",
        IMAGE_IN_INFORMATION: "Image in details",
        MAIN_IMAGE: "Main image",
        TEXT_MODULE_HEADER: "Text module header",
        LOGOTYPE_SHORT: "Logo",
        ALT_TEXT_CLOSE_MENU: "Close menu",
        MISTERIOS_COMBOBOX_DEFAULT_VALUE: "Organization Get IT",
        ALT_TEXT_MENU: "Menu",
        ALT_TEXT_EXIT: "Go out",
        ALT_TEXT_FILTER: "Filter",
        ALT_TEXT_SORT: "Sorting",
        CODE_ALREADY_SENT: "Code already sent",
        SERVICE_ERROR: "Service error, please try again later",
        FAILED_TO_SEND_CODE: "Failed to send code, please try again later",
        WRONG_CODE: "The code is incorrect.",
        CODE_SENT_TO_NUMBER: "Code sent to number",
        ENTER_VALIDATION_DIGITS: "Enter last 4 digits",
        CODE_SUCCESSFULLY_SENT_TO_NUMBER: "Code successfully sent to number",
        SEND_CODE_AGAIN: "Send code again",
        YOU_CAN_RESEND_THE_CODE_VIA: "You can resend the code via",
        MENU_CONST_HOME: "Home",
        MENU_CONST_DESIGN: "Design",
        MENU_CONST_PUSH_MESSAGES: "Push messages",
        MENU_CONST_REVIEWS: "Reviews",
        MENU_CONST_CLIENTS: "Clients",
        FILL_OUT_THE_REQUIRED_FIELD: "Fill out the required field",
        Display_in_telegram_bot: "Display in telegram bot",
        Put_custom_text_on_testimonials_page: "Put custom text on testimonials page",
        Provide_a_link_to_redirect_positive_reviews: "Provide a link to redirect positive reviews",
        Tell_me_what_you_did_not_like: "Tell me what you didn't like",
        Thanks_for_the_feedback: "Thanks for the feedback!",
        Thank_you: "Thank you! Leave us a review",
        Link_to_external_resource_for_feedback: "Link to external resource for feedback",
        Please_enter_valid_phone_number: "Please enter a valid phone number",
        Submit_an_estimate: "Submit an estimate",
        Go: "Go",
        Numbers_and_symbols_are_not_allowed: "Numbers and symbols are not allowed",
        LOYAITY_PROGRAM: "Participate in a loyalty program",
        FEEDBACK_STATISTICS: "Feedback Statistics",
        CARD_STATISTICS: "Guest statistics",
        SELECT_PERIOD: "Select period:",
        TODAY: "Today",
        MONTH_: 'Month',
        QUARTER: 'Quarter',
        YEAR: 'Year',
        REFRESH: 'Refresh',
        GUESTS: 'Guests',
        WORKING_WITH_GUESTS: 'Working with guests',
        COMMUNICATION_TYPE: 'Communication Type',
        TOTAL_GUESTS: 'Total Guests',
        TOTAL_REVIEWS: 'Total Reviews',
        USE_TG_BOT: 'Use TG bot',
        FOR_SELECTED_PERIOD: 'For the selected period',
        REFERRAL_LINK: 'Followed the referral link',
        USE_APPLE:'Use Apple Wallet',
        PREV_PERIOD: 'In prev period',
        MESSAGES_DELIVERED: 'Messages delivered',
        USE_GOOGLE:'Use Google Pay',
        MENU_CONST_UTM: 'UTM',
        TOTAL_UTM: 'Total :',
        CREATE_UTM: 'Create UTM',
        UTM_NAME: "Label name",
        UTM_SOURCE: 'Traffic source',
        UTM_TAG: 'Tag',
        UTM_CREATED: 'Date of creation',
        LABEL_NAME: "UTM name:",
        SAVE: 'Save',
        CLOSE: 'Close',
        CREATE_SOURCE: 'Create a traffic source',
        CREATE_TAG: 'Create tag',
        USERS: 'Users',
        LINK: 'Link',
        ENTER_CORRECT_VALUES: 'Enter the correct values',
        ENTER_SOURCE_VALUE:'enter the source value:',
        SOURCE_VALUE: 'Source value',
        ENTER_TAG_VALUE: 'enter  tag value:',
        TAG_VALUE:'Tag value',
        SELECT_SOURCE: 'select traffic source',
        SELECT_TAG:'select tag',
        LINK_COPIED: 'Link copied',
        TEXT_FIELD: 'Fill in at least one text field',
        Choose_all:'сhoose_all',
        Select_at_least_one_day:'Select at least one day',
        use_Apple_products:'Do you use Apple products? Then press it here',
        Open_in_Safari: 'Open in Safari'
    }
}
